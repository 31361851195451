<template >
  <div>
    <template v-if="item.Translate || item.Translate == undefined">
      <v-select
        :items="itemLanguages"
        v-model="itemDefaultLanguage"
        single-line
        class="pageElementLanguageSelect"
      ></v-select>
    </template>

    <div
      v-for="(translatedValue, translatedValueIndex) in item.Value"
      :key="translatedValueIndex"
    >
      <v-textarea
        v-if="
          itemDefaultLanguage == translatedValueIndex &&
          ((!item.ReadOnly && !needsDisable) || editItemAdmin)
        "
        outlined
        dense
        v-model="item.Value[translatedValueIndex]"
        rows="2"
        :required="item.Required"
        :disabled="
          $helpers.itemNeedsDisable(item.ReadOnly, needsDisable, editItemAdmin)
        "
        :rules="$validation.getValidation(item.FieldValidationFuntion)"
        hide-details="auto"
      >
      </v-textarea>
      <p
        class="mb-0"
        v-if="
          $helpers.itemNeedsDisable(
            item.ReadOnly,
            needsDisable,
            editItemAdmin
          ) && itemDefaultLanguage == translatedValueIndex
        "
      >
        {{ item.Value[translatedValueIndex] }}
      </p>
    </div>

    <template v-if="$store.getters.editMode">
      <hr class="mt-5" />
      <v-row no-gutters class="my-5">
        <v-col cols="12">
          <v-layout column>
            <v-text-field
              v-model.number="item.colWidth"
              hide-details="auto"
              type="number"
              max="12"
              min="1"
              dense
              :label="$t('Column width')"
              class="mt-4 mw-w"
            ></v-text-field>
            <v-switch
              v-model="item.Translate"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Translate item')"
            ></v-switch>
            <v-switch
              v-model="item.ReadOnly"
              class="float-left mr-3"
              hide-details="auto"
              :label="$t('Read-only')"
            ></v-switch>
            <v-switch
              v-model="item.FieldValidation"
              @change="$helpers.changeFieldValidation(item)"
              class="float-left mr-3"
              :label="$t('Field validation')"
            >
            </v-switch>
            <v-row no-gutters>
              <v-col cols="6">
                <v-combobox
                  v-if="item.FieldValidation"
                  :items="$validation.getAllValidationNames()"
                  class="float-left mr-3"
                  :label="$t('Rule')"
                  v-model="item.FieldValidationFuntion"
                  clearable
                  @click:clear="item.FieldValidationFuntion = ''"
                >
                </v-combobox>
              </v-col>
            </v-row>
          </v-layout>
        </v-col>
      </v-row>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: [Array, Object],
    },
    needsDisable: {
      type: Boolean,
    },
    editItemAdmin: {
      type: Boolean,
    },
  },
  data() {
    return {
      itemLanguages: this.getItemLanguages(),
      itemDefaultLanguage: this.getItemDefaultLanguage(),
    };
  },
  methods: {
    getItemDefaultLanguage() {
      return this.$config.contentLanguages[0];
    },
    getItemLanguages() {
      return this.$config.contentLanguages;
    },
  },
};
</script>

<style scoped>
.mw-w {
  min-width: 2rem;
  max-width: 8rem;
}
.pageElementLanguageSelectRichText,
.pageElementLanguageSelect {
  max-width: 65px;
  padding: 0;
}
</style>