var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.item.Translate || _vm.item.Translate == undefined)?[_c('v-select',{staticClass:"pageElementLanguageSelect",attrs:{"items":_vm.itemLanguages,"single-line":""},model:{value:(_vm.itemDefaultLanguage),callback:function ($$v) {_vm.itemDefaultLanguage=$$v},expression:"itemDefaultLanguage"}})]:_vm._e(),_vm._l((_vm.item.Value),function(translatedValue,translatedValueIndex){return _c('div',{key:translatedValueIndex},[(
        _vm.itemDefaultLanguage == translatedValueIndex &&
        ((!_vm.item.ReadOnly && !_vm.needsDisable) || _vm.editItemAdmin)
      )?_c('v-textarea',{attrs:{"outlined":"","dense":"","rows":"2","required":_vm.item.Required,"disabled":_vm.$helpers.itemNeedsDisable(_vm.item.ReadOnly, _vm.needsDisable, _vm.editItemAdmin),"rules":_vm.$validation.getValidation(_vm.item.FieldValidationFuntion),"hide-details":"auto"},model:{value:(_vm.item.Value[translatedValueIndex]),callback:function ($$v) {_vm.$set(_vm.item.Value, translatedValueIndex, $$v)},expression:"item.Value[translatedValueIndex]"}}):_vm._e(),(
        _vm.$helpers.itemNeedsDisable(
          _vm.item.ReadOnly,
          _vm.needsDisable,
          _vm.editItemAdmin
        ) && _vm.itemDefaultLanguage == translatedValueIndex
      )?_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.item.Value[translatedValueIndex])+" ")]):_vm._e()],1)}),(_vm.$store.getters.editMode)?[_c('hr',{staticClass:"mt-5"}),_c('v-row',{staticClass:"my-5",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-layout',{attrs:{"column":""}},[_c('v-text-field',{staticClass:"mt-4 mw-w",attrs:{"hide-details":"auto","type":"number","max":"12","min":"1","dense":"","label":_vm.$t('Column width')},model:{value:(_vm.item.colWidth),callback:function ($$v) {_vm.$set(_vm.item, "colWidth", _vm._n($$v))},expression:"item.colWidth"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"hide-details":"auto","label":_vm.$t('Translate item')},model:{value:(_vm.item.Translate),callback:function ($$v) {_vm.$set(_vm.item, "Translate", $$v)},expression:"item.Translate"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"hide-details":"auto","label":_vm.$t('Read-only')},model:{value:(_vm.item.ReadOnly),callback:function ($$v) {_vm.$set(_vm.item, "ReadOnly", $$v)},expression:"item.ReadOnly"}}),_c('v-switch',{staticClass:"float-left mr-3",attrs:{"label":_vm.$t('Field validation')},on:{"change":function($event){return _vm.$helpers.changeFieldValidation(_vm.item)}},model:{value:(_vm.item.FieldValidation),callback:function ($$v) {_vm.$set(_vm.item, "FieldValidation", $$v)},expression:"item.FieldValidation"}}),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[(_vm.item.FieldValidation)?_c('v-combobox',{staticClass:"float-left mr-3",attrs:{"items":_vm.$validation.getAllValidationNames(),"label":_vm.$t('Rule'),"clearable":""},on:{"click:clear":function($event){_vm.item.FieldValidationFuntion = ''}},model:{value:(_vm.item.FieldValidationFuntion),callback:function ($$v) {_vm.$set(_vm.item, "FieldValidationFuntion", $$v)},expression:"item.FieldValidationFuntion"}}):_vm._e()],1)],1)],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }